<template>
  <div>
    <Loader v-if="isLoading" />
    <div v-else>
      <h3 style="margin-bottom: 25px">{{ $l.get("report-edit.header") }}</h3>
      <ReportForm
        :report="data.report"
        :plans="data.plans"
        :onSubmit="onSubmit"
        v-if="data"
      />
    </div>
  </div>
</template>

<script>
import SubmitFormInteractor from "@/business/report-edit/submit-form";
import InitReportEditInteractor from "@/business/report-edit/init-report-edit";
import ReportEditScreenController from "@/adapters/controllers/screen-report-edit";
import ReportForm from "@/application/components/reports/ReportForm";
import Loader from "@/application/components/Loader";

export default {
  screen_name: "report-edit",
  components: { ReportForm, Loader },
  data() {
    return {
      controller: null,
      interactors: {
        submitForm: null,
        initReportEdit: null
      },
      data: null,
      isLoading: false
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(ReportEditScreenController);

    //{ INTERACTORS
    this.interactors.initReportEdit = this.$injector.get(
      InitReportEditInteractor
    );
    this.interactors.submitForm = this.$injector.get(SubmitFormInteractor);
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    const id = this.$route.params.id;
    this.interactors.initReportEdit.handle(id);
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onSubmit() {
      this.data.report.plans = [];
      for (const plan of this.data.plans) {
        if (plan.isSelected) {
          this.data.report.plans.push({
            plan: plan.id
          });
        }
      }
      for (const variant of this.data.report.variants) {
        if (variant.figure != null) {
          variant.figure = variant.figure.id;
        }
        delete variant.id;
      }
      this.interactors.submitForm.handle(this.data.report);
    }
  }
};
</script>

<style lang="scss"></style>
